body {
  color: #000;
  background: #e8dfd3;
  font-family: 'Yuji Syuku', serif;
  height: auto;
  user-select: none;
}

* {
  margin: 0;
  padding: 0;
  outline: none;
}

*:focus {
  outline: none;
}